import { render, staticRenderFns } from "./NewOrder.vue?vue&type=template&id=40694e20&scoped=true"
import script from "./NewOrder.vue?vue&type=script&lang=js"
export * from "./NewOrder.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/beneficiary.css?vue&type=style&index=0&id=40694e20&prod&scoped=true&lang=css&external"
import style1 from "./NewOrder.vue?vue&type=style&index=1&id=40694e20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40694e20",
  null
  
)

export default component.exports